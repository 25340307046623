.ag-theme-material {

  .ag-overlay-loading-center {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--md-sys-color-on-surface);
    background: var(--md-sys-color-surface);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  
  .ag-root-wrapper {
    border: none !important;
  }
  
  .ag-header-cell {
    font-size: 14px;
    font-family: 'Frutiger LT 55 Roman', sans-serif;
    font-weight: 400;
    color: var(--md-sys-color-on-surface, #FDFBFB);
    background-color: #3c3c5c !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100% !important;
    border-top: none !important;
    border-bottom: none !important;
  }

  .ag-header {
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
  }

  .ag-cell,
  .ag-row {
    border: none !important;
    font-family: 'Frutiger LT 45 Light', sans-serif;
    font-size: 14px;
    color: var(--md-sys-color-on-surface, #FDFBFB);
    background-color: var(--md-sys-color-surface, #282840);
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
  }
  
  .ag-row:not(:last-child) {
    border-bottom: 1px solid #A7ABAF !important;
  }

  .ag-row:last-child {
    border-bottom: none !important;
  }
  
  .ag-overlay-no-rows-wrapper {
    position: absolute;
    top: 40px; 
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: var(--md-sys-color-surface, #282840) !important;
    color: var(--md-sys-color-on-surface, #FDFBFB);
    font-size: 16px;
  }
}

.pagination-container {
  position: fixed;
  bottom: 70px;
  right: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  font-family: "Frutiger LT 45 Light", sans-serif;
  font-size: 12px;
  color: var(--ag-row-font-color);
  background-color: var(--ag-row-background-color);
  border-radius: 4px;
  gap: 5px;

  select {
    width: 16px;
    height: 8px;
  }

  .MuiIconButton-root {
    padding: 1px;
  }
}

.custom-no-rows {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  font-size: 16px;
  color: var(--md-sys-color-on-surface, #FDFBFB);
  background-color: var(--md-sys-color-surface, #282840);
  padding-bottom: 20px;
  border-radius: 4px;
}

