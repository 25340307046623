.newFeedbackComponentContainer {
  width: 340px;
  padding: 16px 24px;
}

.detail-view-panel {
  display: flex;
  width: 340px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  height: 100%;
}

.detail-view-info-tile {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--mat-sys-Surface, #282840);
}

.detail-view-info-tile-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.detail-view-premium-component {
  flex-grow: 1;
  gap: 16px;
  display: flex;
  flex-direction: column;
  overflow-y: auto ;
  width: 100%;
}

.detail-view-recommendation-tile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-grow: 1;
  align-self: stretch;
  border-radius: 12px;
  background: var(--mat-sys-Surface, #282840);
  overflow-y: auto;
}

.detail-view-recommendation-tile-scroller {
  padding: 16px 24px;
  width: 100%;
  overflow-y: auto;
}

.detail-view-recommendation-tile-scroller::-webkit-scrollbar {
  background: transparent;
  width: 15px;
}

.detail-view-recommendation-tile-scroller::-webkit-scrollbar-thumb {
  background: #1E1E34;
  border-radius: 12px;
  border: 4px solid var(--mat-sys-Surface, #282840);
}

.detail-view-recommendation-tile-scroller::-webkit-scrollbar-track {
  margin: 4px;
}

.detail-view-recommendation-tile-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  margin-top: 12px;
}

.detail-view-recommendation-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.detail-view-recommendation-key {
  color: var(--mat-sys-On-Surface, #FDFBFB);
  font-family: var(--mat-sys-Title-Small-Font, "Frutiger LT 55 Roman");
  font-size: var(--mat-sys-Title-Small-Size, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--mat-sys-Title-Small-Line-Height, 20px); /* 142.857% */
  letter-spacing: var(--mat-sys-Title-Small-Tracking, 0.1px);
}

.detail-view-recommendation-value {
  color: var(--mat-sys-On-Surface, #FDFBFB);
  font-family: var(--mat-sys-Display-Medium-Font, "Frutiger LT 45 Light");
  font-size: var(--mat-sys-Body-Medium-Size, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--mat-sys-Body-Medium-Line-Height, 20px); /* 142.857% */
  letter-spacing: var(--mat-sys-Body-Medium-Tracking, 0.25px);
}

.result-detail-view-header {
  font-size: 32px;
  font-family: var(--mat-sys-Headline-Large-Font, "Frutiger LT 45 Light");
  font-weight: 400;
}