// Import the variables and mixins
@import './theme/fontSize.scss';
@import "./theme/spacing.scss";
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
// Use the imported variables and mixins

body {
  font-size: $font-size-body;
  font-family: 'Frutiger LT 45 Light', sans-serif;
  @include respond-to-medium;
  @include respond-to-large;
  color: white;
}

h1 {
  font-size: $font-size-heading1;
  @include respond-to-medium;
  @include respond-to-large;
}

h2 {
  font-size: $font-size-heading2;
  @include respond-to-medium;
  @include respond-to-large;
}

h3 {
  font-size: $font-size-heading3;
  @include respond-to-medium;
  @include respond-to-large;
}

.small-text {
  font-size: $font-size-small;
  @include respond-to-medium;
  @include respond-to-large;
}

.display-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.padding-all {
    padding: $padding-medium;
}

.margin-zero {
    margin: 0px !important
}


.custom-scroll::-webkit-scrollbar
{
  width: 15px;
  background: transparent;
}

.custom-scroll::-webkit-scrollbar-thumb
{
  background: #1E1E34;
  border-radius: 12px;
  border: 4px solid var(--mat-sys-Surface, #282840);
}

.custom-scroll::-webkit-scrollbar-track {
  margin: 4px;
}
